import * as React from "react"
import './loading.scss'
import { ProgressSpinner } from 'primereact/progressspinner';

const LoadingComponent = () => {

  return (
    <div>
      <div className="loading-component">
        <ProgressSpinner/>
      </div>
    </div>
)}

export default LoadingComponent;